import React, { useState, useEffect, FunctionComponent, ReactElement } from 'react'
import CoverVisual from '../components/coverVisual'
import '../styles/index.css'

import Subsection from '../components/subsection'
import LetterFromEditor from '../components/letterFromEditor'
import Layout from '../components/layout'
import { fetchSubsections } from '../components/articles'
import { graphql } from 'gatsby'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { Card, GraphQLQuery } from '../types'
import { SLUGIFY_THAT_SHIT } from '../utils/util'

const regex = /\/static\/(.+-essay|general)\/(.+)\/index.(jpg|png|jpeg)/
const regexSubsection = /\/static\/subsections\/(.+).(jpg|png|jpeg)/

type Data = {
  file: {
    childImageSharp: GatsbyImageFluidProps
  },
  allFile: {
    nodes: {
      absolutePath: string
      childImageSharp: GatsbyImageFluidProps
    }[]
  },
  subsectionImages: {
    nodes: {
      absolutePath: string
      publicURL: string
    }[]
  }
}

const findMatchingImage = (nodes: Data['allFile']['nodes'], title: string) => {
  const n = nodes.find(node => {
  regex.lastIndex = 0
    const slug = regex.exec(node.absolutePath)![2]
    if (slug.includes('love')) console.log(slug, SLUGIFY_THAT_SHIT(title))
    return slug === SLUGIFY_THAT_SHIT(title)
  })
  if (n === undefined) console.log(title)
  console.log(n)
  return n!.childImageSharp
}

const findSubsectionImage = (nodes: Data['subsectionImages']['nodes'], title: string) => {
  const n = nodes.find(node => {
  regexSubsection.lastIndex = 0
    const slug = regexSubsection.exec(node.absolutePath)![1]
    return slug === title
  })
  return n!.publicURL
}

const memory_icon = require('../images/memory_icon.svg') as string
const place_icon = require('../images/place_icon.svg') as string
const shift_icon = require('../images/shift_icon.svg') as string
const psyche_icon = require('../images/psyche_icon.svg') as string
const persona_icon = require('../images/persona_icon.svg') as string

const IndexPage: FunctionComponent<GraphQLQuery<Data>> = ({
  data: {
    file: { childImageSharp },
    allFile: {
      nodes
    },
    subsectionImages: {
      nodes: subNodes,
    }
  }
}): ReactElement => {
  const [subTextType, setSubTextType] = useState<'Authors' | 'Description'>(
    'Description'
  )
  
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  })  


  return (
    <Layout
      postTitle="Flux"
      postImage={undefined}
      postDescription="TheVarsity's 2021 magazine"
      isHome={true}
      className="snap-scroll-container">
      <div className="w-screen bg-gray-50 flex flex-col">
        <div className="flux flux-col justify-center mb-4 md:mb-12 xl:mb-18 2xl:mb-18" id="home">
          <CoverVisual isMobile={isMobile} />
        </div>
        <div className="bg-home">
          <LetterFromEditor />
        </div>
        {isMobile && (
          <>
            <Subsection
              articles={fetchSubsections()[0].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'memory_mobile'),
                quote: '',
              }}
              bgColor="#e3acb9"
              textColor="white"
              category="Memory"
              endSubsectionText="II. PLACE"
              parallaxIcon={memory_icon}
            />
            <Subsection
              articles={fetchSubsections()[1].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'place_mobile'),
                quote: '',
              }}
              bgColor="#f9d86b" // Orig: #F5E51B; yellow. Choices: DED009 (more gold), F9EF6B (soft yellow, CORN)
              textColor="black"
              category="Place"
              endSubsectionText="III. SHIFT"
              parallaxIcon={place_icon}
            />
            <Subsection
              articles={fetchSubsections()[2].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'shift_mobile'),
                quote: '',
                secondaryColour: '#5b6566'
              }}
              bgColor="#CDB9DC"
              textColor="white"
              category="Shift"
              endSubsectionText="IV. PSYCHE"
              parallaxIcon={shift_icon}
            />
            <Subsection
              articles={fetchSubsections()[3].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'psyche_mobile'),
                quote: '',
              }}
              bgColor="#D2E690" // Orig: #AFD51E; green. Choices: AAC92C, A5C71C, 8FAC19
              textColor="white"
              category="Psyche"
              endSubsectionText="V. PERSONA"
              parallaxIcon={psyche_icon}
            />
            <Subsection
              articles={fetchSubsections()[4].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'persona_mobile'),
                quote: '',
              }}
              bgColor="#83ACC3"
              textColor="white"
              category="Persona"
              endSubsectionText=""
              parallaxIcon={persona_icon}
            />
          </>
        )}
        {!isMobile && (
          <>
            <Subsection
              articles={fetchSubsections()[0].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'memory'),
                quote: '',
              }}
              bgColor="#e3acb9"
              textColor="white"
              category="Memory"
              endSubsectionText="II. PLACE"
              parallaxIcon={memory_icon}
            />
            <Subsection
              articles={fetchSubsections()[1].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'place'),
                quote: '',
              }}
              bgColor="#f9d86b" // Orig: #F5E51B; yellow. Choices: DED009 (more gold), F9EF6B (soft yellow, CORN)
              textColor="black"
              category="Place"
              endSubsectionText="III. SHIFT"
              parallaxIcon={place_icon}
            />
            <Subsection
              articles={fetchSubsections()[2].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'shift'),
                quote: '',
                secondaryColour: '#5b6566'
              }}
              bgColor="#CDB9DC"
              textColor="white"
              category="Shift"
              endSubsectionText="IV. PSYCHE"
              parallaxIcon={shift_icon}
            />
            <Subsection
              articles={fetchSubsections()[3].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'psyche'),
                quote: '',
              }}
              bgColor="#D2E690" // Orig: #AFD51E; green. Choices: AAC92C, A5C71C, 8FAC19
              textColor="white"
              category="Psyche"
              endSubsectionText="V. PERSONA"
              parallaxIcon={psyche_icon}
            />
            <Subsection
              articles={fetchSubsections()[4].map((post, idx) => ({
                ...post,
                idx: idx,
                imageFluid: findMatchingImage(nodes, post.title)
              }))}
              subTextType={subTextType}
              visualProps={{
                image: findSubsectionImage(subNodes, 'persona'),
                quote: '',
              }}
              bgColor="#83ACC3"
              textColor="white"
              category="Persona"
              endSubsectionText=""
              parallaxIcon={persona_icon}
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    file(relativePath: { in: "FLUXBACK.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: {absolutePath: {regex: "//static/(.+-essay|general)/(.+)/index.(jpg|png|jpeg)/"}}) {
      nodes {
        absolutePath
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    subsectionImages: allFile(filter: {absolutePath: {regex: "//static/subsections/(.+).(jpg|png|jpeg)/"}}) {
      nodes {
        absolutePath
        publicURL
      }
    }
  }
`
