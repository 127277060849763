import slugify from 'slugify'
import { useMediaQuery } from '@react-hook/media-query'
import React from 'react'
import { Card } from '../../types'
import Image from 'gatsby-image'
import { CardWidths, cardWidthToTailwind } from './types'
import { Link } from 'gatsby'
import { SLUGIFY_THAT_SHIT } from '../../utils/util'

export interface CardProps {
  card: Card
  idx: number
  subTextType: 'Authors' | 'Description'
  imageFile: string
  mobileImageFile: string
}

export const ArticleCard = ({
  card: { title, author, description, imageFluid },
  idx,
  subTextType,
  imageFile,
  mobileImageFile
}: CardProps) => {
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const width = (isMobile)
    ? CardWidths.Small
    : CardWidths.Large
  const widthTailwind = cardWidthToTailwind(width)
  const mobileSpan = isMobile ? "col-span-2 w-3/5" : "col-span-2 w-5/6";
  return (
    <div
      className={`flex flex-col ${mobileSpan} bg-gray-100 rounded-2xl shadow-lg ${widthTailwind} m:${widthTailwind} article-container`}>
      <div className="rounded-md">
        {(isMobile && <Image fluid={imageFluid!.fluid} />)}
        {(!isMobile && <Image fluid={imageFluid!.fluid} />)}
      </div>

      <div className="flex flex-col p-4 mb-4 w-full">
    <Link to={`/article/${SLUGIFY_THAT_SHIT(title)}`}>
        <h1 className="font-bold text-gray-800 text-2xl">{title}</h1>
    </Link>
        <div className="block text-md text-gray-600 whitespace-normal h-4 mb-4">
          {subTextType === 'Authors' ? 'by ' : ''}
          {subTextType === 'Authors' ? author : description}
        </div>
      </div>

      <div className="flex justify-end p-4">
        <p className="text-xs text-gray-500">By {author}</p>
      </div>
    </div>
  )
}
