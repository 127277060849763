import React, { useRef, useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css"; // Yeah, I know I imported a whole library of animations

interface Props {
  left: string
  transform: string
  iconFile: string
}
const ScrollOrange = ({ left, transform, iconFile }: Props) => {
  return (
    <ScrollAnimation 
      style={{
        position: 'relative',
        marginTop: '-4vh',
      }}
      animateIn="animate__animated animate__slideInLeft animate__slower"
      animateOut="animate__rotateOut"
      offset={0}
      animateOnce={true}
      animatePreScroll={true}
    >
      <embed // Note: This styling stuff is indeed temporary until we get a better graphic for the orange.
        style={{
          height: '50px',
          width: '50px',
          display: 'inline-block',
          position: 'absolute',
          left: left,
          bottom: 0,
          transform: transform,
          textAlign: 'center',
          animation: 'all 1s',
          WebkitTransition: 'all 1.0s ease-in',
          MozTransition: 'all 1.0s ease-out',
          transition: 'all 1.0s ease-out'
        }} 
        src={iconFile}  
      />
    </ScrollAnimation>
  )
}

export default ScrollOrange
