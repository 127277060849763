import React, { useEffect, useRef, useState } from 'react'
import SiriWave from './wave'
//import flux from '../components/flux.mp4'
//import flux_mobile from '../components/flux_mobile.mp4'

interface CoverVisualProps {
  isMobile?: boolean
}

const CoverVisual = ({ isMobile = false }: CoverVisualProps) => {
  const flux = 'https://vrsty.b-cdn.net/flux%20(3).mp4'
  const flux_mobile = 'https://vrsty.b-cdn.net/flux-mobil-compressed.mp4'
  const containerRef = useRef<HTMLDivElement>(null)
  const [siriRef, setSiriRef] = useState<SiriWave | undefined>(undefined)
  useEffect(() => {
    if (containerRef.current) {
      setSiriRef(new SiriWave({
        container: containerRef.current,
        cover: true,
        color: 'black',
        style: 'ios9'
      })
    )
    // return (() => siriRef?.dispose())
    }
  }, [containerRef.current])
  // return (
//     <div className="h-screen text-white bg-gray-900" ref={containerRef} id="siri-container">
//         <h1 className="absolute text-7xl italic opacity-95 glitch" style={{
// left: '50%',
// top: '50%',
// transform: 'translate(-50%, -50%)'
//         }}>FLUX</h1>
//     </div>
//   )
if (isMobile) {
  return (
    <div dangerouslySetInnerHTML={{__html: `
        <video className="w-screen" playsinline loop autoplay muted>
          <source src="https://vrsty.b-cdn.net/flux-mobil-compressed.mp4" type="video/mp4" />
        </video>
    `}}>

    </div>
  )
}
  return (
    <div>
        <video className="w-screen" playsInline={true} loop={true} autoPlay={true} muted={true}>
          <source src={flux} type="video/mp4" />
        </video>
    </div>
  )
}

export default CoverVisual
