import { Card } from '../types'

const fetchSubsections = () => {
    const a: Card[] = [
        {
            title: 'Ghost Month in Penang: an island haunted by the spectre of dengue fever',
            author: 'Zhen Chew',
            description: "The disease is a metaphor for the culture of fear, xenophobia pervading Malaysia",
            imageFile: 'dengue_fever.jpg',
            mobileImageFile: 'dengue_fever_1by1.jpg'
        },
        {
            title: 'Nostalgic for the present',
            author: 'Vurjeet Madan',
            description: "A video essay about noticing the little details of life as we experience them",
            imageFile: 'nostalgic_for_the_present.jpg',
            mobileImageFile: 'nostalgic_for_the_present.jpg'
        },
        {
            title: 'It might actually be good to confront your friends about middle school drama',
            author: 'Lauren Alexander',
            description: 'That time I finally brought up an eighth grade birthday party thrown without me',
            imageFile: 'revisiting_middle_school_drama.jpg',
            mobileImageFile: 'revisiting_middle_school_drama_1by1.jpg'
        },
        {
            title: 'A community built by change: the many movements of the Punjabi diaspora',
            author: 'Angad Deol',
            description: 'Even with our backs against the wall, we find a way to move forward',
            imageFile: 'a_community_built_by_change.jpg',
            mobileImageFile: 'a_community_built_by_change_1by1.jpg'
        },
        {
            title: 'The generation that broke all the rules: Toronto’s hippie life in the ’60s',
            author: 'Emory Claire Mitchell',
            description: 'Krystina Godzik-Wooley recounts the partying, hitchhiking, and rock stars of her past',
            imageFile: 'running_parallel.jpg',
            mobileImageFile: 'running_parallel_1by1.jpg'
        }
    ]

    const b: Card[] = [
        {
            title: 'A love letter to my favourite plants, as told through illustrations',
            author: 'Isabella Tan',
            description: 'In a world in flux, gardening provides a sense of stability and calm',
            imageFile: 'a_love_letter_to_my_favourite_plants.jpg',
            mobileImageFile: 'a_love_letter_to_my_favourite_plants_1by1.jpg'
        },
        {
            title: 'Tik Tok at U of T: how students have made it big on the viral app',
            author: 'Muzna Erum',
            description: 'Varied experiences about community building, claims of anti-Black bias in algorithms',
            imageFile: 'tiktok_stars.jpg',
            mobileImageFile: 'tiktok_stars_1by1.jpg'
        },
        {
            title: 'What it means to pack a suitcase — lessons from my late father ',
            author: 'Sherry Ning',
            description: 'Learning to order my luggage and my life around versatility and care',
            imageFile: 'how_to_pack_a_suitcase.jpg',
            mobileImageFile: 'how_to_pack_a_suitcase_1by1.jpg'
        },
        {
            title: 'Build your own Batcave: Thang Vu on what it means to be an artist today',
            author: 'Gladys Lou',
            description: 'A U of T designer on shifting to online exhibits, dream to open a diner in Vietnam',
            imageFile: 'presentation_of_art.jpg',
            mobileImageFile: 'presentation_of_art_1by1.jpg'
        },
    ]

    const c: Card[] = [
        {
            title: 'Flying in circles: on adulthood and university from your childhood bedroom',
            author: 'Kate Haberl',
            description: 'When you finally move out, you may need home more than you realize',
            imageFile: 'flying_in_circles.jpg',
            mobileImageFile: 'flying_in_circles_1by1.jpg'
        },
        {
            title: 'Feeling stuck as a first-year during the pandemic',
            author: 'Anastasia Kasirye',
            description: 'A letter on the loneliness of watching my friends leave while I stayed behind',
            imageFile: 'pandemic-square.jpg',
            mobileImageFile: 'pandemic.jpg'
        },
        {
            title: 'Living in motion pictures: a stop-motion animation photo essay',
            author: 'Nathan Ching',
            description: 'Trying to capture the way life unfolds before us in frames',
            imageFile: 'living_in_motion.jpg',
            mobileImageFile: 'living_in_motion_1by1.jpg'
        },
        {
            title: 'Redefining relationships: the lockdown’s effect on the familial, platonic, and romantic',
            author: 'Janus Kwong',
            description: 'From long-distance breakups to returning home, our lives have been irrevocably changed',
            imageFile: 'redefining_family_social_circle_quarantine.jpg',
            mobileImageFile: 'redefining_family_social_circle_quarantine_1by1.jpg'
        },
        {
            title: 'How listening to plants helped me believe in people again',
            author: 'Wambui Waiganjo',
            description: 'We are romantic, communal people who seek connection in any and everything',
            imageFile: 'plants.jpg',
            mobileImageFile: 'plants_1by1.jpg'
        },
        {
            title: 'Making bread during COVID-19: life on the frontlines doing minimum wage (and unpaid) work',
            author: 'Sky Kapoor',
            description: 'Grocery store employees to nursing students — how overlooked occupations became revered nationwide',
            imageFile: 'essential_workers_covid.jpg',
            mobileImageFile: 'essential_workers_covid_1by1.jpg'
        }
    ]

    const d: Card[] = [
        {
            title: 'What to do when you’re (barely) hanging on to hope',
            author: 'Nancy Dutra',
            description: 'From listening to the words of Mr. Rogers to learning when to quit',
            imageFile: 'hanging_onto_hope_through_pandemic.jpg',
            mobileImageFile: 'hanging_onto_hope_through_pandemic_1by1.jpg'
        },
        {
            title: 'My dreamland rolodex: escapism as a source of relief — and why it’s okay',
            author: 'Sherene Almjawer',
            description: 'Coping by intensive daydreaming for everything I can’t control',
            imageFile: 'escapism_yearn_for_other.jpg',
            mobileImageFile: 'escapism_yearn_for_other_1by1.jpg'
        },
        {
            title: 'Slouching toward the sky: finding God on an afternoon run',
            author: 'Caleb Chan',
            description: 'Confronting existential weariness and Sunday school memories around my city’s dike system',
            imageFile: 'running_spirituality.jpg',
            mobileImageFile: 'running_spirituality_1by1.jpg'
        },
        {
            title: 'Misinformation, uncertainty, and conspiracies, oh my!',
            author: 'Shankeri Vijayakumar',
            description: 'What happens to the information ecosystem during crises like a pandemic?',
            imageFile: 'misinformation_info_ecosystem_during_pandemic.jpg',
            mobileImageFile: 'misinformation_info_ecosystem_during_pandemic_1by1.jpg'
        }
    ]
    
    const e: Card[] = [
        {
            title: 'Guide to being a well-informed global citizen during the pandemic ',
            author: 'Angel Hsieh',
            description: 'Reviewing journalistic credibility, news stories that have defined a complicated year',
            imageFile: 'well_informed.gif',
            mobileImageFile: 'well_informed_1by1.gif'
        },
        {
            title: 'Theatre criticism has come to a fork in the road',
            author: 'Joshua Chong',
            description: 'The practice needs to undergo a reckoning of inclusivity',
            imageFile: 'theatre_changes.jpg',
            mobileImageFile: 'theatre_changes_1by1.jpg'
        },
        {
            title: 'Toronto transplant: my friends share how the city compares to their distant homes',
            author: 'Jesse McDougall',
            description: 'In the end, the city is a reflection of those who live in it',
            imageFile: 'toronto_transplant.jpg',
            mobileImageFile: 'toronto_transplant_1by1.jpg'
        },
        {
            title: 'My connotated urban spaces: what happened when I lost my childhood playground',
            author: 'Valerie Yao',
            description: 'To what extent should we overlook personal attachments when redeveloping a space?',
            imageFile: 'my_connotated_urban_spaces.jpg',
            mobileImageFile: 'my_connotated_urban_spaces_1by1.jpg'
        },
        {
            title: 'Gardens of the future: how your plants at home can help address national food security',
            author: 'Jadine Ngan',
            description: 'Artist Lori Weidenhammer on community building through victory gardening',
            imageFile: 'gardens_of_the_future.jpg',
            mobileImageFile: 'gardens_of_the_future_1by1.jpg'
        },
    ]

    const subsections = [a, b, c, d, e];
    return subsections;
}

export {fetchSubsections};