export enum CardWidths {
  Small = '16rem',
  Large = '24rem'
}

export const cardWidthToTailwind = (width: CardWidths) => {
  switch (width) {
    case CardWidths.Small:
      return 'w-64'
    case CardWidths.Large:
      return 'w-96'
  }
}
