import React from 'react'
import {Card} from '../types'
import Cards from './card/cards'
import {VisualProps} from './subsectionvisual'

interface Props {
    articles: (Card & { idx: number })[]
    subTextType: 'Authors' | 'Description'
    visualProps: VisualProps
    textColor: string
    bgColor: string
    category: string
    endSubsectionText: string
    parallaxIcon: string
  }

const Subsection = ({articles, subTextType, visualProps, textColor, bgColor, category, endSubsectionText, parallaxIcon }: Props) => {
    return (
      <div id={category.toLowerCase()} className="h-screen w-screen electric-scroll snap-scroll" style={{ 
          backgroundColor: bgColor, 
          color: textColor,
          boxShadow: '0 0 10px 10px white'
      }}>
        <Cards
          articles={articles}
          subTextType={subTextType}
          visualProps={visualProps}
          endSubsectionText={endSubsectionText}
          parallaxIcon={parallaxIcon}
        />
      </div>
    )
}

export default Subsection;