import slugify from "slugify"

export const makeParallaxImg = (splitSrc: string) => {
  let src = splitSrc.split('src="')[1]

  if (src.includes('title')) {
    const titleSplit = src.split('" title="')
    src = titleSplit[0]
    const title = titleSplit[1]
    return `<div class="parallax" style="background-image: url('${src}')" /> <div class="w-full text-center photo-cap-bg"><p style="color:white;">${title}</p></div>`
  }

  // return `<div class="parallax" style="background-image: url('${src}')" />`;

  return `<div class="parallax" style="background-image: url('${src}')" />`
  // return `<img src=${src} />`;
}

export const cleanContent = (content: string, secondary: string) => {
  const colorChange = content.replace(
    /<h2>/g,
    `<h2 class='text-2xl font-bold text-center pt-0' style='color:${secondary}'>`
  )

  const splitByImg = colorChange.split('<img')

  const finalSections = []

  for (const section of splitByImg) {
    // prettier-ignore
    if (section.includes("src=")) {
      // Img section
      const [imgSrc, rest] = section.split('">');

      const imgSection = makeParallaxImg(imgSrc);
      finalSections.push({ image: true, content: imgSection });
      if (rest.length > 5) {
        finalSections.push({ image: false, content: rest });
      }
    } else if (section.length > 5) {
      finalSections.push({ image: false, content: section });
    }
  }
  for (const [index, elm] of finalSections.entries()) {
    if (elm.content.includes('<p>')) {
      finalSections[index] = {
        ...elm,
        content: elm.content.replace('<p>', "<p class='firstPara'>")
      }
    }
  }
  return finalSections
}

export const SLUGIFY_THAT_SHIT = (str: string) => slugify(str.toLowerCase(), {remove: /[*,+~.()'"!:@]/g})