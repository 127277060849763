import { useMediaQuery } from "@react-hook/media-query";
import React from "react";
import {useRef} from "react";

interface VisualProps {
    image: string // temporary until i get some rando graphic?
    quote: string
    secondaryColour?: string
}

const SubsectionVisual = ({ image, quote, secondaryColour = "black" }: VisualProps) => {
    const isMobile = useMediaQuery('only screen and (max-width: 768px)');
    const columnSpan = isMobile
    ? "col-span-2"
    : "col-span-4"
    let subsectionClass = "bg-white black h-auto flex flex-col " + columnSpan;
    
    return (<div 
                className={subsectionClass}
                style={{
                    backgroundImage: "url(" + image + ")",
                    color: "white",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div style={{
                    position: "relative",
                    alignItems: "center",
                    top: "50%"
                }}>
                    <span style={{ position: "absolute", color: secondaryColour, paddingRight: "60px", paddingTop: "16px", right: "15%" }}>
                        SCROLL
                    </span>
                    <svg
                        style={{
                            position: "absolute",
                            right: "15%",
                        }}
                        height={64}
                        viewBox="0 0 512 512"
                        width={64}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M160 115.4L180.7 96 352 256 180.7 416 160 396.7 310.5 256z" fill={secondaryColour} />
                    </svg>
                </div>
                <div className="pt-8 text-center align-middle">
                    { (quote != "" && <h1 className="font-bold text-4xl">“{ quote }”</h1> )}
                </div>
            </div>
        )
}

export {SubsectionVisual, VisualProps}