import { useRef, useState } from 'react'

const useOrange = () => {
  const scrollArea = useRef<HTMLDivElement>(null)
  const [orangeState, setOrangeState] = useState({
    left: '25px',
    transform: 'rotate(0deg)'
  })
  const onScroll = () => {
    const s =
      scrollArea!.current!.scrollLeft /
      (scrollArea!.current!.scrollWidth - scrollArea!.current!.clientWidth)

    const transform = 'rotate(' + (s * 360 * 2.5).toString() + 'deg)'
    const leftDist =
      ((window.screen.availWidth * s - 60 < 10
        ? 10
        : (window.screen.availWidth * 0.997) * s - 50)) + "px";
    setOrangeState({
      left: leftDist,
      transform: transform
    })
  }

  return {
    scrollArea,
    onScroll,
    orangeState
  }
}

export default useOrange
