import { useMediaQuery } from '@react-hook/media-query'
import React from 'react'
import {useRef} from 'react';
import useOrange from '../../hooks/useOrangeScroll'
import { Card } from '../../types'
import ScrollOrange from '../orange'
import { ArticleCard } from './card'
import { CardWidths } from './types'
import {VisualProps, SubsectionVisual} from '../subsectionvisual'
import { fromPairs } from 'lodash'

interface Props {
  articles: (Card & { idx: number })[]
  subTextType: 'Authors' | 'Description'
  visualProps: VisualProps
  endSubsectionText: string
  parallaxIcon: string
}

const Cards = ({ articles, subTextType, visualProps, endSubsectionText, parallaxIcon }: Props) => {
  const { scrollArea, onScroll, orangeState } = useOrange()
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const width = isMobile
    ? CardWidths.Small
    : CardWidths.Large
  const scrollBy = () => {
    window.scrollBy({
      left: 0, 
      top: window.innerHeight,
      behavior: 'smooth'
    });
  }

  const cardClass = "grid auto-cols-fr flex m-auto grid-flow-col w-screen align-middle " + (isMobile ? "" : "gap-x-8");

  return (
    <>
      <div
        onScroll={onScroll}
        ref={scrollArea}
        style={{
          gridTemplateColumns: `repeat(auto-fill,minmax(${width},1fr))`,
          gridAutoColumns: `minmax(${width},1fr)`
        }}
        className="gap-1 md:gap-8 h-full grid auto-cols-fr grid-flow-col overflow-x-scroll horizontall-scroll overflow-y-hidden whitespace-no-wrap">

        { visualProps.image != "" && 
            <SubsectionVisual {...visualProps} />
        }

        <span 
            style={{
                gridTemplateColumns: `repeat(auto-fill,minmax(${width},1fr))`,
                gridAutoColumns: `minmax(${width},1fr)`
            }}
            className={cardClass}
        >
            {articles.map((card, idx) => (
            <ArticleCard
                key={`article-card-${card.title}`}
                idx={idx}
                card={card}
                subTextType={subTextType}
                imageFile={card.imageFile}
                mobileImageFile={card.mobileImageFile}
            />
            ))}
            <div className="flex flex-col" 
              style={{
                position: "relative",
              }}
            >
              <p style={{
                position: "absolute",
                bottom: "6%",
                right: "3%"
              }}>
                {endSubsectionText}
              </p>
              <svg  // SVG for arrow. Can replace if needed.
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 32 32"
                style={{ 
                  width: "30px",
                  position: "absolute",
                  bottom: 0,
                  right: "4%"
                }}
                onClick={scrollBy} 
              >
                <path
                  d="M32 16.016l-5.672-5.664-6.312 6.312V0h-8.023v16.664l-6.32-6.32L0 16.016 16 32l16-15.984z"
                  fill="#030104"
                />
              </svg>
            </div>
        </span>
      </div>
      <ScrollOrange iconFile={parallaxIcon} left={orangeState.left} transform={orangeState.transform} />
    </>
  )
}

export default Cards
