import React, { FunctionComponent, ReactElement } from 'react'
import { useMediaQuery } from "@react-hook/media-query";

const LetterFromEditor: FunctionComponent = (): ReactElement => {
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const wrapperClass = "flex w-screen bg-gray-50 snap-scroll " + ((isMobile) ? "pt-16 pb-12" : "pt-16 pb-20");
  return (
    <>
      <div className={wrapperClass} id="letter-from-editor">
        <div className="sepia shadow-md bg-white rounded-md antialiased max-w-7xl mx-auto px-4 py-4 sm:px-8 sm:py-6 lg:px-16 lg:py-8 flex flex-col justify-center items-center">
          <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl max-w-prose mx-auto text-xl leading-relaxed subpixel-antialiased">
            <h1 className="pb-8 text-left md:text-center">
              Letter from the Editors
            </h1>
            <div className="font-normal text-gray-500">
            <p>For a while, naïve optimism carried us through the beginning of the past year. But as the months dragged on, the small victories ceased to mean as much. People are feeling spent, crushed, yearning for an ideal past that may not have ever existed.</p>
            <p>With this grim reality in mind, when discussing what we wanted to do for our digital magazine, we knew that our contributors would not have the same types of stories in them that they had a year ago. We have all changed, maybe forever.</p>
            <p>While holding this thought, something else came to us: what happens when we embrace the changes? We started talking about how most of the time, moments in our lives don’t exist on a binary of ‘good’ or ‘bad’ — we encounter a continuum of experiences that are constantly moving and shifting. This reality is not something we have control over, but when we allow the flux of life to wash over us, we can learn about ourselves and the world around us.</p>
            <p>“<b><i>FLUX</i></b>” is full of stories about people who make lemonade, budge mountains, and rebuild communities. We would like to thank every one of our writers for being vulnerable, sharing their joy and sorrow, and not shying away from what lies in the middle. This magazine wouldn’t be possible without you, and it also wouldn’t be possible without every other person who coaxed this vision to life. For the web developers, copy editors, illustrators, photographers, magazine assistants, business team, and more: “<b><i>FLUX</i></b>” is all of ours.</p>
            <p>So, let’s embrace the chaos of this past year — as vaguely threatening as that sounds. And to every person who pulled apart the liminal state we’ve been living in and filled a magazine with those stories, maybe we can think of our spring 2021 issue as a milepost, marking not only what has been, nor what’s to come, but everything else in between. </p><br />
            <p className="italic">— <b>Stephanie Bai</b>, Magazine Editor, and <b>Nathalie Whitten</b>, Creative Director</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LetterFromEditor
